.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* // */
.middle {
  width: 100%;
  text-align: center;
}
.middle input[type="radio"] {
  display: none;
}
.middle input[type="radio"]:checked + .box {
  background-color: #007e90;
}
.middle input[type="radio"]:checked + .box span {
  color: #282c3495;
  transform: translateY(70px);
}
.middle input[type="radio"]:checked + .box span:before {
  transform: translateY(0px);
  opacity: 1;
}
.middle .box {
  width: 200px;
  height: 200px;
  background-color: #282c3495;
  transition: all 250ms ease;
  will-change: transition;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  position: relative;
  font-family: "Inter", sans-serif;
  font-weight: 900;
}
.middle .box:active {
  transform: translateY(10px);
}
.middle .box span {
  position: absolute;
  /* transform: translate(0, 50%); */
  padding-top: 30px;
  left: 0;
  right: 0;
  transition: all 300ms ease;
  font-size: 1.5em;
  user-select: none;
  color: #fefefe;
}
.middle .box span:before {
  font-size: 1.2em;
  font-family: FontAwesome;
  display: block;
  transform: translateY(-80px);
  opacity: 0;
  transition: all 300ms ease-in-out;
  font-weight: normal;
  color: white;
}
.middle .front-end span:before {
  content: "";
}
.middle .back-end span:before {
  content: "";
}
.middle p {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-weight: 400;
}
.middle p a {
  text-decoration: underline;
  font-weight: bold;
  color: #fff;
}
.middle p span:after {
  content: "";
  font-family: FontAwesome;
  color: yellow;
}

/**/
.ant-descriptions .ant-descriptions-item-content {
  color: #fff;
}

/**/
.ant-modal-mask {
  background-color: rgba(1, 1, 1, 0.9) !important;
  backdrop-filter: blur(10px);
}
