.box {
  width: 100%;
  height: 400px;
  position: relative;
}

.first_position,
.second_position,
.third_position,
.fourth_position,
.fifth_position,
.central {
  background: #00000077;
  color: aliceblue;
  width: 80px;
  height: 80px;
  margin: 2px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
}

.central {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.first_position {
  position: absolute;
  left: calc(50% - 100px);
  top: calc(50% - 40px);
  transform: translate(-50%, -50%);
}
.second_position {
  position: absolute;
  left: calc(50% + 0px);
  top: calc(50% - 100px);
  transform: translate(-50%, -50%);
}
.third_position {
  position: absolute;
  left: calc(50% + 100px);
  top: calc(50% - 40px);
  transform: translate(-50%, -50%);
}
.fourth_position {
  position: absolute;
  left: calc(50% - 60px);
  top: calc(50% + 80px);
  transform: translate(-50%, -50%);
}
.fifth_position {
  position: absolute;
  left: calc(50% + 60px);
  top: calc(50% + 80px);
  transform: translate(-50%, -50%);
}
